/* mantine-datatable overrides */
.mantine-datatable {
  border-radius: 16px;
  overflow: hidden;
  border: 1px solid #E7E7E7;
}

/* Use stable class names */
.mantine-datatable-td:first-of-type {
  padding-left: 16px !important;
}

.mantine-datatable-td:last-of-type {
  padding-right: 16px !important;
}

.mantine-datatable-th:first-of-type {
  padding-left: 16px !important;
}

.mantine-datatable-th:last-of-type {
  padding-right: 16px !important;
}

.mantine-datatable-row {
  height: 56px !important;
  min-height: 56px !important;
  font-size: 14px !important;
  font-family: "Montserrat" !important;
  font-weight: 500 !important;
}

.mantine-datatable-td {
  height: 56px !important;
  line-height: 56px !important;
  padding: 7px 10px !important;
  font-size: 14px !important;
  font-family: "Montserrat" !important;
}

.mantine-datatable-header {
  background-color: #F8FAFB !important;
  padding: 7px 16px !important;
}

.mantine-datatable-th {
  padding: 7px 10px !important;
  height: 50px !important;
  background-color: #F8FAFB !important;
  font-family: "Montserrat" !important;
  font-size: 14px !important;
  font-weight: 600 !important;
} 

/* mantine wrapper (header/footer) */
.mantine-wrapper {
  background-color: #FFFFFF !important;
  border-radius: 24px !important;
  padding: 16px !important;
  .title {
      font-family: Montserrat;
      font-size: 16px;
      font-weight: 600;
      line-height: 19.5px;
      text-align: left;
      text-underline-position: from-font;
      text-decoration-skip-ink: none;
    }
}

/* mantine checkbox */
.mantine-projectview-Checkbox-input {
  border-color: #000000 !important;
  border-radius: 4px !important;
}

.mantine-projectview-Checkbox-input .mantine-Checkbox-inner {
  width: 14px !important;
  height: 14px !important;
}

.mantine-projectview-Checkbox-input .mantine-Checkbox-input {
  width: 14px !important;
  height: 14px !important;
}

.mantine-projecttable-Checkbox-input {
  border-color: #9E9E9E !important;
  border-radius: 4px !important;
}

.mantine-projecttable-Checkbox-input .mantine-Checkbox-inner {
  width: 14px !important;
  height: 14px !important;
}

.mantine-projecttable-Checkbox-input .mantine-Checkbox-input {
  width: 14px !important;
  height: 14px !important;
}

/* mantine accordion */

.mantine-Accordion-root {
  font-family: Montserrat;
  font-size: 14px !important;
  font-weight: 400 !important;
}

.mantine-Accordion-label {
  font-size: 14px !important;
}

.mantine-Accordion-root .mantine-Text-root {
  font-size: 14px !important;
}

.mantine-Accordion-chevron {
  margin-left: 8px !important;
}

.process-accordion-item  {
  font-weight: 500 !important;
  background-color: #FFFFFF !important;
  border: 1px solid #E7E7E7 !important;
  border-radius: 8px !important;
  gap: 12px !important;

}

/* Only remove border from last child if it's NOT a process-accordion-item */
.mantine-Accordion-item:not(.process-accordion-item):last-child {
  border: none !important;
}

.mantine-Accordion-content {
  padding-bottom: 5px !important;
}

.mantine-Accordion-root > .mantine-Accordion-item > .mantine-Accordion-control[data-active] {
  background-color: #F6FAFD !important;
}

/* Add spacing between direct children of Accordion root */
.process-accordion-item {
  margin-bottom: 8px !important;
}

/* Remove margin from last direct child to avoid extra space */
.process-accordion-item:last-child {
  margin-bottom: 0 !important;
}

.process-accordion-item .mantine-Accordion-control {
  height: 40px !important;
  min-height: 40px !important;
}

/* Common button styles */
button.mantine-Button-root {
  font-family: "Montserrat" !important;
  font-size: 12px !important;
  font-weight: 500 !important;
  border-radius: 8px !important;
  padding: 11px 22px !important;
  height: 40px !important;
}

/* Primary button styles */
button.mantine-Button-root[data-variant="filled"] {
  background-color: #000102 !important;
  border: none !important;
}

/* Secondary button styles */
button.mantine-Button-root[data-variant="outline"] {
  background-color: #FFFFFF !important;
  border: 1px solid #E7E7E7 !important;
  color: #000102 !important;
}

/* Mantine form input styles */
.mantine-TextInput-input,
.mantine-NumberInput-input,
.mantine-PasswordInput-input,
.mantine-Select-input,
.mantine-DateInput-input,
.mantine-TimeInput-input {
  height: 40px !important;
  border-radius: 8px !important;
  font-family: "Montserrat" !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  letter-spacing: 0 !important;
}
.mantine-RichTextEditor-content {
    border-radius: 8px !important;
    font-family: "Montserrat" !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    letter-spacing: 0 !important;
}

/* Add gap between form fields */
.mantine-InputWrapper-root {
  margin-bottom: 12px !important;
}

/* Style form labels */
.mantine-InputWrapper-label {
  margin-bottom: 8px !important;
  font-weight: 500 !important;
}

/* Remove margin from last form field to avoid extra space */
.mantine-InputWrapper-root:last-child {
  margin-bottom: 0 !important;
}

/* MDXEditor text styles */
.mdxeditor {
  font-family: "Montserrat" !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  letter-spacing: 0 !important;
}

/* Ensure the content area also inherits these styles */
.mdxeditor [contenteditable="true"] {
  font-family: "Montserrat" !important;
  font-weight: 400 !important;
  font-size: 14px !important;
  letter-spacing: 0 !important;
}

/* Remove border radius from buttons in ButtonGroup */
.mantine-ButtonGroup-group .mantine-Button-root {
  border-radius: 0 !important;
}

/* Restore border radius for first and last buttons in ButtonGroup */
.mantine-ButtonGroup-group .mantine-Button-root:first-of-type {
  border-top-left-radius: 8px !important;
  border-bottom-left-radius: 8px !important;
}

.mantine-ButtonGroup-group .mantine-Button-root:last-of-type {
  border-top-right-radius: 8px !important;
  border-bottom-right-radius: 8px !important;
}

/* ActionIcon styles to match buttons */
.mantine-ActionIcon-root {
  border-radius: 8px !important;
}

/* Outlined ActionIcon */
.mantine-ActionIcon-root[data-variant="outline"] {
  background-color: #FFFFFF !important;
  border: 1px solid #E7E7E7 !important;
  color: #000102 !important;
}

/* Filled ActionIcon */
.mantine-ActionIcon-root[data-variant="filled"] {
  background-color: #000102 !important;
  border: none !important;
}

/* mantine Text component */
.mantine-Text-root {
  font-family: "Montserrat" !important;
}

/* MultiSelect dropdown with high z-index */
.mantine-MultiSelect-dropdown {
  z-index: 99999 !important;
}

/* Select dropdown with high z-index */
.mantine-Select-dropdown {
  z-index: 99999 !important;
}

/* Fix TimeInput behavior */
.mantine-TimeInput-input {
  font-variant-numeric: tabular-nums !important;
  letter-spacing: 1px !important;
  text-align: center !important;
}

/* Menu dropdown with high z-index */
.mantine-Menu-dropdown {
  z-index: 99999 !important;
}
